"use client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTelegram } from "@fortawesome/free-brands-svg-icons";

import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  const url_path = location.pathname;
  const nav_menu = [
    {
      path: "/",
      name: "home",
      is_active: url_path === "/",
    },
    {
      path: "/about-us",
      name: "about us",
      is_active: url_path.toLowerCase() === "/about-us",
    },
    {
      path: "/services",
      name: "services",
      is_active: url_path.toLowerCase().startsWith("/services"),
      subMenu: [
        {
          path: "/services/automotives",
          name: "automotives",
          is_active: url_path.toLowerCase() === "/services/automotives",
        },
        {
          path: "/services/electronics",
          name: "electronics",
          is_active: url_path.toLowerCase() === "/services/electronics",
        },
        {
          path: "/services/jewelries",
          name: "jewelries",
          is_active: url_path.toLowerCase() === "/services/jewelries",
        },
        {
          path: "/services/real-estate",
          name: "real estate",
          is_active: url_path.toLowerCase() === "/services/real-estate",
        },
      ],
    },
    {
      path: "/events",
      name: "events",
      is_active: url_path.toLowerCase() === "/events",
    },
    {
      path: "/career",
      name: "career",
      is_active: url_path.toLowerCase().startsWith("/career"),
      subMenu: [
        {
          path: "/career/discover-jobs",
          name: "discover jobs",
          is_active: url_path.toLowerCase() === "/career/discover-jobs",
        },
        {
          path: "/career/public-holiday",
          name: "public holiday",
          is_active: url_path.toLowerCase() === "/career/public-holiday",
        },
      ],
    },
  ];

  const link_icon = [
    {
      icon: faFacebook,
      color: "#14A0F9",
      link: "https://facebook.com",
    },
    {
      icon: faTelegram,
      color: "#33AFE3",
      link: "https://telegram.com",
    },
  ];
  return (
    <nav className="navbar bg-base-100 shadow-md px-5 md:px-10">
      <div className="navbar-start">
        <div className="dropdown">
          <label tabIndex={0} className="btn btn-sm mr-3 btn-ghost lg:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
          </label>
          {/* on small screen show harmber bar */}
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52 gap-1 font-semibold">
            {nav_menu.map((menu, index) => {
              if (menu.subMenu) {
                if (menu.subMenu?.length > 0) {
                  return (
                    <li key={index} className="dropdown dropdown-right">
                      <details>
                        <summary>
                          <Link to={menu.path} tabIndex={0}>
                            {menu.name.toUpperCase()}
                          </Link>
                        </summary>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu shadow bg-base-100 rounded-box w-52">
                          {menu.subMenu?.map((subMenu, indexSubMenu) => (
                            <li key={indexSubMenu}>
                              <Link className={subMenu.is_active ? "btn-active" : ""} to={subMenu.path}>
                                {subMenu.name.toUpperCase()}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </details>
                    </li>
                  );
                }
              }
              return (
                <li key={index}>
                  <Link className={menu.is_active ? "btn-active" : ""} to={menu.path}>
                    {menu.name.toUpperCase()}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <Link to="/" className="relative h-12 w-12 md:h-20 md:w-20">
          <img alt="logo" src={require("../assets/logo/logo.png")} className="w-full h-fit" />
        </Link>
      </div>

      {/* on large screen */}
      <div className="navbar-center hidden lg:flex font-semibold ">
        <ul className="menu menu-horizontal px-1 gap-3">
          {nav_menu.map((menu, index) => {
            if (menu.subMenu) {
              if (menu.subMenu?.length > 0) {
                return (
                  <li key={index} className="dropdown dropdown-hover">
                    <Link to={menu.path} tabIndex={0}>
                      {menu.name.toUpperCase()}
                    </Link>
                    <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                      {menu.subMenu?.map((subMenu, indexSubMenu) => (
                        <li key={indexSubMenu}>
                          <Link to={subMenu.path}>{subMenu.name.toUpperCase()}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }
            }
            return (
              <li key={index}>
                <Link className={menu.is_active ? "btn-active" : ""} to={menu.path}>
                  {menu.name.toUpperCase()}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="navbar-end gap-4">
        {link_icon.map((item, index) => (
          <Link to={item.link} target="_blank" key={index}>
            <FontAwesomeIcon icon={item.icon} color={item.color} size="xl" />
          </Link>
        ))}
      </div>
    </nav>
  );
}
