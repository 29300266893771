import Carousel, { CarouselItems } from "./carousel";
type BannerProps = {
  imageUrl: string | CarouselItems[];
};

export default function Banner(props: BannerProps) {
  const { imageUrl } = props;

  function ImageBanner() {
    let src = "";
    if (typeof imageUrl === "string") {
      src = imageUrl;
      return <img src={src} className="w-[100%] h-[100%]" alt="banner" />;
    } else {
      return <Carousel items={imageUrl} />;
    }
  }

  return <div className="w-full h-[150px] md:h-[250px] relative">{<ImageBanner />}</div>;
}
