import { Link } from "react-router-dom";
import { services } from "../../data";
import { Container, Banner } from "../../components";

export default function Automotives() {
  const other_services = services.filter((service) => service.title !== "automotives");

  return (
    <>
      <Banner imageUrl={require("../../assets/images/banner/services/automotives_banner.jpg")} />
      <Container>
        <div className="p-3 md:p-0">
          <h1 className="text-xl md:text-3xl uppercase font-bold py-3 md:py-6">Automotives</h1>
          <p>
            CASH-U-Up Company offers loan with pawn service as we accept automotive such as car, motor, and car/ motor ID. We have experts
            to check and give you a market price with the suitable interest rate. CASH-U-Up has a strong and safe place to keep your car/
            motor. We have technician taken care of your automobile.
          </p>
          <h1 className="text-md md:text-xl uppercase font-bold py-3 md:py-6 text-center">Other service</h1>
          <div className="flex justify-evenly ">
            {other_services.map((service, index) => (
              <Link key={index} to={service.path} className="btn btn-sm md:btn-md btn-primary">
                {service.title}
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
}
