"use client";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";

export type CarouselItems = {
  imageUrl: string;
  title?: string;
};

type CarouselProps = {
  items: CarouselItems[];
  title?: boolean;
  duration?: number;
};

export default function Carousel(props: CarouselProps) {
  const { items, title = true, duration = 5000 } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const lastIndex = items.length - 1;

  useEffect(() => {
    const timeOut = setTimeout(() => {
      next();
    }, duration);
    return () => {
      clearTimeout(timeOut);
    };
  });

  function next() {
    if (currentIndex === lastIndex) setCurrentIndex(0);
    else setCurrentIndex((current) => current + 1);
  }

  function prev() {
    if (currentIndex === 0) setCurrentIndex(lastIndex);
    else setCurrentIndex((current) => current - 1);
  }

  if (items.length === 0) return <></>;

  return (
    <div className="w-full h-full relative carousel transition ease-in-out delay-300 duration-300 ">
      <img alt="..." height="100%" width="100%" src={items[currentIndex].imageUrl} />
      <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
        <button onClick={prev} className="btn btn-circle btn-sm md:btn-md">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button onClick={next} className="btn btn-circle btn-sm md:btn-md">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {title && items[currentIndex].title && (
        <div className="absolute w-full bottom-5 left-5">
          <h3 className="uppercase text-md md:text-lg font-bold ">{items[currentIndex].title}</h3>
        </div>
      )}
      <div className="absolute w-full bottom-1 flex justify-center gap-2">
        {items.map((item, index) => (
          <FontAwesomeIcon
            onClick={() => setCurrentIndex(index)}
            key={index}
            icon={faCircle}
            size={index === currentIndex ? "sm" : "xs"}
            color={index === currentIndex ? "#444" : "#fff"}
            style={{ cursor: "pointer" }}
          />
        ))}
      </div>
    </div>
  );
}
