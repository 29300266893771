import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../../lib";
import { Input } from "../../components";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("123");
  const navigte = useNavigate();

  const login = () => {
    if (username === "" || password === "") {
      setErrorText("username or password cannot empty");
      setError(true);
      return;
    }
    setError(false);

    request({ method: "POST", url: "auth/login", data: { username, password } }).then((respone) => {
      const { status, token } = respone.data;
      if (!status) {
        setErrorText("incorrect username or password");
        setError(true);
        return;
      }
      //redirect to home page
      window.localStorage.setItem("login", "1");
      window.localStorage.setItem("token", token);
      navigte("/auth", { replace: true });
    });
  };
  return (
    <div className="w-full h-screen flex justify-center items-center bg-cyan-800 ">
      <div className=" w-[90%] md:w-[70%] lg:w-[450px] p-3 md:p-6 rounded-lg border-zinc-600 border-2 bg-gradient-to-br from-gray-100 to-gray-400">
        <h3 className="font-semibold text-xl md:text-2xl text-center">Login</h3>
        <Input label="Username" required value={username} onChange={(e) => setUsername(e.target.value)} />
        <Input label="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
        {error && <div className="text-sm text-red-500 font-semibold text-right mt-3">{errorText}</div>}
        <div className="text-center py-3">
          <button onClick={login} className="btn btn-primary btn-sm">
            Login
          </button>
        </div>
      </div>
    </div>
  );
}
