import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Loading, Input } from "../../../components";
import { request } from "../../../lib";

export default function NewEvent() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mainImage, setMainImage] = useState<File | undefined>(undefined);
  const [eventImage, setEventImage] = useState<File[] | undefined>(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function save() {
    if (!title || !description || !mainImage || !eventImage) {
      setError(true);
      return;
    }
    setLoading(true);
    await request({ method: "POST", url: "events", data: { title, description, mainImage, eventImage }, mulitimedia: true });
    navigate("/auth/events");
  }
  if (loading) return <Loading />;
  return (
    <Container>
      <h3 className="text-center font-semibold text-lg md:text-3xl capitalize my-3 md:my-6">create new event</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 px-3 md:px-0">
        <Input type="textarea" label="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        <Input type="textarea" label="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
        <div className="flex w-full gap-3 mt-3">
          <label className="font-semibold self-center grow text-end">Main Image</label>
          <input
            onChange={(e) => {
              const files = e.target.files;
              if (files) setMainImage(files[0]);
            }}
            className="border-2 border-black rounded-md p-1 w-[70%]"
            type="file"
            accept="image/*"
          />
        </div>

        <div className="flex w-full gap-3 mt-3">
          <label className="font-semibold self-center grow text-end">Event Image</label>
          <input
            onChange={(e) => {
              const files = e.target.files;
              const list: File[] = [];
              if (files) {
                for (let i = 0; i < files.length; i++) {
                  list.push(files[i]);
                }
              }
              setEventImage(list);
            }}
            className="border-2 border-black rounded-md p-1 w-[70%]"
            type="file"
            accept="image/*"
            multiple
          />
        </div>
      </div>
      {error && <div className="text-end text-red-500 font-semibold py-3">All field cannot empty.</div>}
      <div className="justify-center my-5 flex gap-3">
        <button onClick={save} className="btn btn-primary text-white">
          Save
        </button>
        <Link to="/auth/events" className="btn btn-error text-white">
          Back
        </Link>
      </div>
    </Container>
  );
}
