import { ReactNode } from "react";
import BackendNavbar from "../components/backend-navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import Login from "../pages/backend/login";

type Props = {
  children?: ReactNode;
};

export default function BackendLayout({ children }: Props) {
  const login = window.localStorage.getItem("login");

  if (!login) {
    return <Login />;
  }

  return (
    <>
      <Helmet>
        <title>Trust Zone</title>
      </Helmet>
      <main className="flex min-h-screen flex-col">
        <BackendNavbar />
        <div className="flex-1" style={{ fontFamily: "Battambang" }}>
          {children}
          <Outlet />
        </div>
        <Footer />
      </main>
    </>
  );
}
