import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { khmer_date, request } from "../../../lib";
import { ConfirmDelete, Input, Loading } from "../../../components";

export default function BackendJobs() {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [ready, setReady] = useState(false);

  const [selectedJobID, setSelectedJobID] = useState<number | null>(null);

  //requirement
  const requirementModalRef = useRef<HTMLDialogElement>(null);
  const newRequirementModalRef = useRef<HTMLDialogElement>(null);
  const editRequirementModalRef = useRef<HTMLDialogElement>(null);

  const [selectedRequirement, setSelectedRequirement] = useState<number | null>(null);
  const [editRequirement, setEditRequirement] = useState("");
  const [newRequirement, setNewRequirement] = useState("");
  const [requirements, setRequirements] = useState<JobRequirement[]>([]);

  //responsibility
  const newResponsibilityModalRef = useRef<HTMLDialogElement>(null);
  const editResponsibilityModalRef = useRef<HTMLDialogElement>(null);
  const responsibilityModalRef = useRef<HTMLDialogElement>(null);

  const [selectedResponsibility, setSelectedResponsibility] = useState<number | null>(null);
  const [editResponsibility, setEditResponsibility] = useState("");
  const [newResponsibility, setNewResponsibility] = useState("");
  const [responsibilities, setResponsibilities] = useState<JobResponsibily[]>([]);

  useEffect(() => {
    request({ url: "jobs" })
      .then((res) => setJobs(res.data))
      .finally(() => setReady(true));
  }, []);

  async function openRequirement(id: number) {
    setSelectedJobID(id);
    const res = await request({ url: `jobs/${id}/requirements` });
    setRequirements(res.data);
    requirementModalRef.current?.showModal();
  }
  async function openResponsibility(id: number) {
    setSelectedJobID(id);
    const res = await request({ url: `jobs/${id}/responsibilities` });
    setResponsibilities(res.data);
    responsibilityModalRef.current?.showModal();
  }

  async function saveNewRequirement() {
    if (!newRequirement) return;
    request({ url: `jobs/${selectedJobID}/requirements`, method: "POST", data: { requirement: newRequirement } }).then((res) =>
      setRequirements((current) => [...current, res.data]),
    );
    setNewRequirement("");
    newRequirementModalRef.current?.close();
  }
  async function saveEditRequirement() {
    if (!newRequirement) return;
    await request({
      url: `jobs/${selectedJobID}/requirements/${selectedRequirement}`,
      method: "PUT",
      data: { requirement: editRequirement },
    });
    setRequirements((current) => {
      const index = requirements.findIndex((req) => req.id === selectedRequirement);
      const update = { ...requirements[index], requirement: editRequirement };
      return [...current.slice(0, index), update, ...current.slice(index + 1)];
    });
    editRequirementModalRef.current?.close();
  }

  async function saveNewResponsibility() {
    if (!newResponsibility) return;
    request({ url: `jobs/${selectedJobID}/responsibilities`, method: "POST", data: { responsibility: newResponsibility } }).then((res) =>
      setResponsibilities((current) => [...current, res.data]),
    );
    setNewResponsibility("");
    newResponsibilityModalRef.current?.close();
  }
  async function saveEditResponsibility() {
    if (!newResponsibility) return;
    request({
      url: `jobs/${selectedJobID}/responsibilities/${selectedResponsibility}`,
      method: "PUT",
      data: { responsibility: editResponsibility },
    });
    setResponsibilities((current) => {
      const index = responsibilities.findIndex((req) => req.id === selectedResponsibility);
      const update = { ...responsibilities[index], responsibility: editResponsibility };
      return [...current.slice(0, index), update, ...current.slice(index + 1)];
    });
    editResponsibilityModalRef.current?.close();
  }

  if (!ready) return <Loading />;

  return (
    <>
      <h3 className="capitalize font-semibold text-lg md:text-3xl text-center my-3 md:my-6">Jobs list</h3>
      <div className="text-end">
        <Link
          to="/auth/jobs/new"
          className="btn btn-primary mx-2 mb-5 btn-sm md:btn-md text-xs md:text-sm text-white font-semibold uppercase"
        >
          Addnew
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr className="font-extrabold text-sm md:text-lg bg-cyan-800 text-white capitalize">
              <th>No</th>
              <th>Title</th>
              <th>Function</th>
              <th>Location</th>
              <th>Post Date</th>
              <th>Close Date</th>
              <th>Status</th>
              <th>Type</th>
              <th>requirements</th>
              <th>responsibilies</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {jobs.length === 0 && (
              <tr>
                <td colSpan={10} align="center">
                  No Jobs
                </td>
              </tr>
            )}
            {jobs.map((job, index) => {
              const status = new Date(job.close_date).getTime() >= new Date().getTime() ? "Open" : "Close";

              return (
                <tr key={index}>
                  <td align="center">{index + 1}</td>
                  <td className="min-w-[90px]">{job.title}</td>
                  <td className="min-w-[90px]">{job.department}</td>
                  <td className="min-w-[90px]">{job.location}</td>
                  <td className="min-w-[90px]">{khmer_date(job.post_date)}</td>
                  <td className="min-w-[90px]">{khmer_date(job.close_date)}</td>
                  <td>{status}</td>
                  <td>{job.type}</td>
                  <td onClick={() => openRequirement(job.id)} className="cursor-pointer underline text-center">
                    view
                  </td>
                  <td onClick={() => openResponsibility(job.id)} className="cursor-pointer underline text-center">
                    view
                  </td>
                  <td align="center">
                    <div className="flex gap-2 md:gap-3 justify-center items-center">
                      <Link to={`/auth/jobs/edit/${job.id}`} className="text-blue-600 ">
                        <FontAwesomeIcon icon={faEdit} size="xl" />
                      </Link>
                      <ConfirmDelete onDelete={() => {}}>
                        <FontAwesomeIcon className="text-red-500" icon={faTrash} size="xl" />
                      </ConfirmDelete>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <dialog ref={requirementModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl backdrop:rounded-none">
          <div className="flex justify-between">
            <h4 className="text-lg md:text-2xl mb-2">Requirements</h4>
            <button onClick={() => newRequirementModalRef.current?.showModal()} className="btn btn-success btn-sm">
              Add
            </button>
          </div>
          {requirements.length === 0 && <div className="font-semibold text-center">Not set yet.</div>}
          {requirements.length > 0 && (
            <div>
              <ul className="list-disc">
                {requirements.map((req, index) => (
                  <li key={index} className="ml-4 md:ml-8 ">
                    {req.requirement}
                    <button
                      onClick={() => {
                        setEditRequirement(req.requirement);
                        setSelectedRequirement(req.id);
                        editRequirementModalRef.current?.showModal();
                      }}
                      className="text-cyan-700 mx-3"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <ConfirmDelete
                      onDelete={() => {
                        request({ url: `jobs/${selectedJobID}/requirements/${req.id}`, method: "DELETE" }).then(() => {
                          setRequirements(requirements.filter((r) => r.id !== req.id));
                        });
                      }}
                    >
                      <FontAwesomeIcon className="text-red-500" icon={faTrash} />
                    </ConfirmDelete>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      <dialog ref={responsibilityModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl backdrop:rounded-none">
          <div className="flex justify-between">
            <h4 className="text-lg md:text-2xl mb-2">Responsibilities</h4>
            <button onClick={() => newResponsibilityModalRef.current?.showModal()} className="btn btn-sm btn-success">
              Add
            </button>
          </div>
          {responsibilities.length === 0 && <div className="font-semibold text-center">Not set yet.</div>}
          {responsibilities.length > 0 && (
            <div>
              <ul className="list-disc">
                {responsibilities.map((res, index) => (
                  <li key={index} className="ml-4 md:ml-8 ">
                    {res.responsibility}
                    <button
                      onClick={() => {
                        setEditResponsibility(res.responsibility);
                        setSelectedResponsibility(res.id);
                        editResponsibilityModalRef.current?.showModal();
                      }}
                      className="text-cyan-700 mx-3"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <ConfirmDelete
                      onDelete={() => {
                        request({ url: `jobs/${selectedJobID}/responsibilities/${res.id}`, method: "DELETE" }).then(() => {
                          setResponsibilities(responsibilities.filter((r) => r.id !== res.id));
                        });
                      }}
                    >
                      <FontAwesomeIcon className="text-red-500" icon={faTrash} />
                    </ConfirmDelete>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      {/* edit responsiblity modal */}

      <dialog ref={editResponsibilityModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl py-8 backdrop:rounded-none">
          <Input
            type="textarea"
            label="Responsibility"
            rows={4}
            value={editResponsibility}
            onChange={(e) => setEditResponsibility(e.target.value)}
            required
          />
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveEditResponsibility} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => editResponsibilityModalRef.current?.close()} className="btn btn-error btn-sm text-white">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      {/* new responsiblity modal */}

      <dialog ref={newResponsibilityModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl py-8 backdrop:rounded-none">
          <Input
            type="textarea"
            label="Responsibility"
            rows={4}
            value={newResponsibility}
            onChange={(e) => setNewResponsibility(e.target.value)}
            required
          />
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveNewResponsibility} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => newResponsibilityModalRef.current?.close()} className="btn btn-error btn-sm text-white">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      {/* edit requirement modal */}

      <dialog ref={editRequirementModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl py-8 backdrop:rounded-none">
          <Input
            type="textarea"
            label="Requirement"
            rows={4}
            value={editRequirement}
            onChange={(e) => setEditRequirement(e.target.value)}
            required
          />
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveEditRequirement} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => editRequirementModalRef.current?.close()} className="btn btn-error btn-sm text-white">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>

      {/* new requirement modal */}

      <dialog ref={newRequirementModalRef} className="modal">
        <div className="modal-box w-11/12 max-w-5xl py-8 backdrop:rounded-none">
          <Input
            type="textarea"
            label="Requirement"
            value={newRequirement}
            onChange={(e) => setNewRequirement(e.target.value)}
            rows={4}
            required
          />
          <div className="justify-center mt-3 flex gap-3">
            <button onClick={saveNewRequirement} className="btn btn-primary btn-sm">
              Save
            </button>
            <button onClick={() => newRequirementModalRef.current?.close()} className="btn btn-error btn-sm text-white">
              Close
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
