export const services = [
  {
    title: "automotives",
    path: "/services/automotives",
    imageUrl: require("../assets/images/services/automotives.jpg"),
  },
  {
    title: "jewelries",
    path: "/services/jewelries",
    imageUrl: require("../assets/images/services/jewelries.jpg"),
  },
  {
    title: "electronics",
    path: "/services/electronics",
    imageUrl: require("../assets/images/services/electronics.jpg"),
  },
  {
    title: "real estate",
    path: "/services/real-estate",
    imageUrl: require("../assets/images/services/real_estate.jpg"),
  },
];

export const typeOption = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
];

export const nav_item = [];
